const Departments: any = [
    {
        name: "General and Laparoscopic Surgery",
        desc: "Laparoscopic Surgery uses small incisions that are less painful and fast-healing. This only requires a short hospital stay and has fewer complications.",
        path: 'general-laproscopic-surgery',
        doctors: [
            {
                name: 'Dr. C C Suresh',
                deg: 'MS (General Surgeon)',
            },
            {
                name: 'Dr. Abdurahman Ambadi',
                deg: 'MS (Gen.Surg), M.SAGES (US), Dip. MAS',
            },

            {
                name: 'Dr. Nabeel M V',
                deg: 'MS (General Surgeon)',
            },
            {
                name: 'Dr. Abdul Haleem',
                deg: 'MS (General Surgeon)',
            },
            {
                name: 'Dr. Ramesh P K',
                deg: 'MS (General Surgeon), FMAS, FIAGES',
            },
            {
                name: 'Dr. Aashiq K Nawal',
                deg: 'MBBS, MS (General Surgeon), DNB',
            }
        ]
    },
    {
        name: "Plastic and Reconstructive Surgery",
        desc: "Plastic and Reconstructive Surgeries are invasive and extensive operations done to repair damaged tissues to restore normal function or enhance the visual appeal of an organ",
        path: 'plastic-reconstructive-surgery',
        doctors: [
            {
                name: 'Dr. Hafiz Muhammed K',
                deg: 'MS (Gen.Surg), MCh (Plastic Surg), FRCS (Ed), Fellowship in Reconstructive Microsurgery',
            },
            {
                name: 'Dr. John Oommen',
                deg: 'MS (General Surgeon), MCh (Plastic Surgery)',
            },
            {
                name: 'Dr. Kader Kalathingal',
                deg: 'MS (General Surgeon), MCh (Plastic Surgery)',
            }
        ]
    },
    {
        name: "Vascular Surgery",
        desc: "Vascular Surgery is a surgical subspecialty in which diseases of the vascular system, or arteries, veins and lymphatic circulation, are managed by medical therapy, minimally-invasive catheter procedures, and surgical reconstruction.",
        path: 'vascular-surgery',
        doctors: [
            {
              name: 'Dr. Ranjish A T',
              deg: 'MS (Gen.Surg), Fellowship in Vascular Surgery',
            },
          ],
    },
    {

        name: "Surgical Gynecology",
        desc: "Surgical Gynecology is a branch of surgery concerned with the treatment of benign conditions, cancer, infertility, and incontinence in women.",
        path: 'surgical-gynecology',
        doctors: [
            {
                name: 'Dr. Abdul Vahab',
                deg: 'MD (O & G)',
            },
            {
                name: 'Dr. Fathima Thasneem E A',
                deg: 'MS, DNB (O & G), Fellow in Minimally In- vasive Surgery (FMIS)',
            },

            {
                name: 'Dr. Minu Justin',
                deg: 'MD (O & G)',
            },
            {
                name: 'Dr. Majee Mohamed',
                deg: 'DGO',
            },
        ]
    },
    {
        name: "Surgical Oncology",
        desc: "Surgical Oncology uses surgery to find and remove harmful tumors. This is also used in the diagnosis and finding the spread of cancer in the body.",
        path: 'surgical-oncology',
        doctors: [
            {
                name: 'Dr. Surij Salih',
                deg: 'MS MCh (Head & Neck Oncology)',
            },
        ],
    },
    {
        name: "Surgical Urology",
        desc: "Minimally Invasive Surgery uses small incisions that are less painful and fast-healing. This only requires a short hospital stay and has fewer complications.",
        path: 'surgical-urology',
        doctors: [
            {
                name: 'Dr. Felix Cardoza',
                deg: 'MS, MCh (Urology)',
            },           
        ]
    },
    {
        name: "Orthopedic & Joint Replacement",
        desc: "Orthopedic Surgery deals with the surgical treatment of defects and injuries caused to bones, joints and ligaments.",
        path: 'orthopedic-joint-replacement',
        doctors: [
            {
                name: 'Dr. Ashok Parapoyil',
                deg: 'D. Ortho, DNB',
            },
            {
                name: 'Dr. Janees M C',
                deg: 'MS (Ortho)',
            },

            {
                name: 'Dr. Riyas Ali Aryadan',
                deg: 'MS (Ortho)',
            },            
        ]
    },
    // {
    //   name: "Head and Neck Surgery",
    //   desc: "Head and Neck Surgery is done for the treatment of disorders affecting ears, respiratory and upper alimentary system.",
    // },
    {
        name: "Oral and Maxillofacial Surgery",
        desc: "Maxillofacial Surgery deals with the treatment of the defects and disorders in the face, neck, jaw and sinuses. This may include the reconstruction of broken bones.",
        path: 'oral-maxillofacial-surgery',
        doctors: [
            {
                name: 'Dr. Ani John Peter',
                deg: 'MDS (Oral & Maxillo Facial Surgeon), DN (Orthodontics)',
            },
            {
                name: 'Dr. Pravish V',
                deg: 'MDS (Oral & Maxillo Facial Surgeon)',
            },

            {
                name: 'Dr. Shaji',
                deg: 'MDS (Orthodontics)',
            },
            {
                name: 'Dr. Sahla Ambadi',
                deg: 'MDS',
            },
        ]
    },

    // {
    //     name: "Surgical Gynaecology",
    //     desc: "Surgical Gynaecology is a branch of surgery concerned with the treatment of benign conditions, cancer, infertility, and incontinence in women.",
    // },
    // {
    //     name: "Pediatric Surgery",
    //     desc: "Pediatric Surgery is the surgery involving the treatment of infants and children. This includes surgery for conditions like congenital malformations, chest wall deformities, childhood tumors etc.",
    // },
    // {
    //     name: "Orthopedic Surgery",
    //     desc: "Orthopedic Surgery deals with the surgical treatment of defects and injuries caused to bones, joints and ligaments.",
    // },
    // {
    //     name: "Urological Surgery",
    //     desc: "Urological Surgery is the surgery concerned with the problems in the urinal tract of males and females. This includes treatments for dysfunction, obstructions, malignancies and inflammatory diseases.",
    // },
    
    {
        name: "Anaesthesia and Pain Medicine",
        desc: "Our Department of Anaesthesia and Pain Medicine is engaged with patients throughout their surgical journey; right from pre-operative all the way until discharge. We aim to maintain a central role in promoting patient safety and quality.",
        path: 'anaesthesia-pain-medicine',
        doctors: [
            {
                name: 'Dr. Sabri Faisal',
                deg: 'MD (Anaesthesia)',
            },
            {
                name: 'Dr. Sunsheen A P',
                deg: 'DA',
            },

            {
                name: 'Dr. Saidalavi',
                deg: 'DA',
            },
            {
                name: 'Dr. Kunhimohammed',
                deg: 'DA',
            },           
        ]
    },
];

export default Departments;
