import React from "react";
import { graphql, Link, PageProps } from "gatsby";

import Layout from "@/components/Layout";
import Plate from "@/components/Plate";
import Hero from "@/components/Hero";

import { Text, Box, Flex, Container } from "theme-ui";
import { m } from "framer-motion";

import Departments from '../../components/pager'

export interface HomeProps {
  data?: any;
}


const Testimonials: React.FC<HomeProps> = ({ data }) => {
  const postEdges = (data && data.caseStudies && data.caseStudies.edges) || [];

  return (
    <Layout>
      <Plate variant="styles.contentFrame">
        <Container>
          <Plate variant="styles.contentFrame">
            <Text as="h1" variant="pageTitle">
              Departments
            </Text>
            <Flex sx={{ flexWrap: 'wrap' }}>
              {Departments.map((dep: any) => (
                <Box sx={{ mb: 3, maxWidth: "60ch", mr: 6, p: 5, pl: 0, pt: 5 }}>
                  <Text as="h1" variant="sectionTitle" sx={{ mb: 1 }}>{dep.name}</Text>
                  <Text as="p" variant="sectionBody" sx={{ fontSize: 1 }}>
                    {dep.desc}
                  </Text>
                  <Link to={`/departments/${dep.path}`}>
                    <Text as="h2" sx={{ fontWeight: 100 }}>View Details</Text>
                  </Link>
                </Box>
              ))}
            </Flex>
          </Plate>
        </Container>
      </Plate>
    </Layout>
  );
};

export default Testimonials;
